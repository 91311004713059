<template>
  <MoleculeGrid
    :title="$t('pages.users.title')"
    :loading="loading"
    :columns="columns"
    :rows="users"
    :search="['fullname', 'phone', 'email']"
    :pagination="true"
    :outline="false"
  >
    <template #actions>
      <MoleculeExportButton
        table="users"
        :file-name="$t('pages.users.title')"
        :disabled="loading"
      />
    </template>
    <template #fullname="{ data }">
      <MoleculeAvatarDetails
        :title="data.fullname"
        :subtitle="data.email"
      />
    </template>
    <template #phone="{ data }"><AtomPhone>{{ data.phone }}</AtomPhone></template>
    <template #role="{ data }">
      <AtomStatus
        :color="{
          'blue': data.role == 'admin',
          'cyan': data.role == 'manager'
        }"
      >
        {{ $te(`role.${data.role}`) ? $t(`role.${data.role}`) : $t('role.user') }}
      </AtomStatus>
    </template>
    <template v-if="isAdmin" #action="{ data }">
      <div class="text-end">
        <AtomButton
          v-if="data.role !== 'admin' && data.role !== 'manager'"
          class="align-text-top"
          @click="makeManager(data.email)"
        >
          {{ $t('action.make_manager') }}
        </AtomButton>
        <AtomButton
          v-if="data.role !== 'admin' && data.role === 'manager'"
          class="align-text-top"
          color="danger"
          variant="outline"
          @click="clearRole(data.email)"
        >
          {{ $t('action.clear') }}
        </AtomButton>
        <AtomButton
          class="align-text-top ms-2"
          @click="copyId(data.id)"
        >
          <copy-icon />
        </AtomButton>
      </div>
    </template>
  </MoleculeGrid>
</template>

<script>
import { functions } from '@/firebase'
import { mapGetters, mapActions, mapMutations } from 'vuex'

import MoleculeAvatarDetails from '@/components/MoleculeAvatarDetails'
import MoleculeGrid from '@/components/MoleculeGrid'
import MoleculeExportButton from '@/components/MoleculeExportButton'
import AtomButton from '@/components/AtomButton'
import AtomPhone from '@/components/AtomPhone'
import AtomStatus from '@/components/AtomStatus'

export default {
  name: 'OrganismUsersGrid',

  components: {
    MoleculeAvatarDetails,
    MoleculeGrid,
    MoleculeExportButton,
    AtomButton,
    AtomPhone,
    AtomStatus,
  },

  data: () => ({
    loading: true,
  }),

  computed: {
    ...mapGetters(['users', 'isAdmin']),

    columns () {
      return [
        { key: 'idx', name: '№', sortable: true },
        { key: 'fullname', name: this.$t('client.info'), sortable: true },
        { key: 'phone', name: this.$t('phone.label'), sortable: true },
        { key: 'role', name: this.$t('role.label'), sortable: true },
        { key: 'action' },
      ]
    },
  },

  async mounted () {
    try {
      await this.fetchUsers()
    } catch {} finally {
      this.loading = false
    }
  },

  methods: {
    ...mapActions(['fetchUsers']),
    ...mapMutations(['setError']),

    async makeManager (email) {
      try {
        this.loading = true
        const setManager = functions.httpsCallable('admin-setManager')
        await setManager({ email, accessLevel: 2 })
        this.$router.go()
      } catch (e) {
        this.setError(e)
      } finally {
        this.loading = false
      }
    },

    async clearRole (email) {
      try {
        this.loading = true
        const clearClaims = functions.httpsCallable('admin-clearClaims')
        await clearClaims({ email })
        this.$router.go()
      } catch (e) {
        this.setError(e)
      } finally {
        this.loading = false
      }
    },

    async copyId (id) {
      try {
        await navigator.clipboard.writeText(id)
        this.$notify({
          title: this.$t('action.success'),
          text: 'ID пользователя успешно скопировано в буфер обмена',
          type: 'success',
        })
      } catch (e) {
        console.log(e)
      }
    },
  },
}
</script>
