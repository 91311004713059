<template>
  <AtomDropdown
    v-model="dropdownShow"
    class="btn-group"
    :disabled="disabled || loading"
  >
    <AtomButton
      color="primary"
      class="dropdown-toggle"
      :disabled="disabled || loading"
    >
      <span
        v-if="loading"
        class="spinner-border spinner-border-sm me-2"
        role="status"
      />
      <table-export-icon v-else />
      {{ $t('action.export') }}
      <chevron-down-icon class="ms-2 me-0" />
    </AtomButton>
    <template #dropdown>
      <div
        v-for="format in formats"
        :key="format.format"
        class="dropdown-item cursor-pointer"
        :class="{ disabled: loading || disabled }"
        @click.prevent="exportHandler(format.format)"
      >
        {{ format.label }}
        <span class="badge bg-primary-lt ms-auto">.{{ format.format }}</span>
      </div>
    </template>
  </AtomDropdown>
</template>

<script>
import axios from 'axios'
import { mapMutations } from 'vuex'
import AtomButton from '@/components/AtomButton'
import AtomDropdown from '@/components/AtomDropdown'

export default {
  name: 'MoleculeExportButton',

  components: {
    AtomButton,
    AtomDropdown,
  },

  props: {
    table: {
      type: String,
      required: true,
    },

    fileName: {
      type: String,
      default: 'export',
    },

    disabled: {
      type: Boolean,
      default: false,
    },
  },

  data: () => ({
    dropdownShow: false,
    loading: false,
    formats: [
      {
        label: 'Excel',
        format: 'xlsx',
      },
      {
        label: 'CSV',
        format: 'csv',
      },
      {
        label: 'JSON',
        format: 'json',
      },
    ],
  }),

  methods: {
    ...mapMutations(['setError']),

    async exportHandler (format = 'xlsx') {
      if (this.loading) {
        return
      }

      this.dropdownShow = false
      this.loading = true

      try {
        const user = await this.$store.dispatch('getCurrentUser')
        const token = await user.getIdToken()
        const response = await axios({
          url: `https://us-central1-${process.env.VUE_APP_PROJECT_ID}.cloudfunctions.net/export-data`,
          method: 'GET',
          responseType: 'blob',
          params: {
            table: this.table,
            format,
          },
          headers: {
            Authorization: `Bearer ${token}`,
          },
        })

        const href = URL.createObjectURL(response.data)
        const link = document.createElement('a')
        link.href = href
        link.setAttribute('download', `${this.fileName}.${format}`)
        document.body.appendChild(link)
        link.click()
        document.body.removeChild(link)
        URL.revokeObjectURL(href)
      } catch (e) {
        this.setError(e)
      } finally {
        this.loading = false
      }
    },
  },
}
</script>

<style lang="scss" scoped>
  .dropdown-toggle:after {
    display: none;
  }
</style>
