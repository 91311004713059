<template>
  <OrganismUsersGrid />
</template>

<script>
import OrganismUsersGrid from '@/components/OrganismUsersGrid'

export default {
  title: vm => vm.$t('pages.users.title'),

  components: {
    OrganismUsersGrid,
  },
}
</script>
